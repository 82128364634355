<template>
    <div id="app">
      <div v-if="defaultHeader">
        <site-header />
      </div>
      <loader v-if="showLoading"></loader>
      <router-view/>
      <div v-if="defaultFooter">
        <site-footer />
      </div>
      <location-permission />
<!--      <leads-storage />-->
      <notifications></notifications>
      <vue-confirm-dialog></vue-confirm-dialog>
    </div>
</template>

<script>
import SiteHeader from './components/SiteHeader.vue'
import SiteFooter from './components/SiteFooter.vue'
import loader from './components/LoaderGlobal.vue'
import LocationPermission from './components/LocationPermission.vue'
// import LeadsStorage from './components/lead-storage/LeadsStorage.vue'
import { mapState } from 'vuex';
export default {
  components: {
    SiteHeader,
    SiteFooter,
    loader,
    LocationPermission,
    // LeadsStorage,
  },
  data(){
    return{
      defaultHeader: true,
      defaultFooter: true,
    }
  },
  watch: {
    "$route.params": {
      handler() {
        if (
          this.$route.name == "error" || this.$route.name == 'decline-reminder-email'
        ) {
          this.defaultHeader = false;
          this.defaultFooter = false;
        } else {
          this.defaultHeader = true;
          this.defaultFooter = true;
        }
      },
      immediate: true,
    },
  },
  computed: {
    ...mapState({
      showLoading: state => state.showLoading
    })
  }
}
</script>

<style>
@import url('assets/fonts/stylesheet.css');
@import url('assets/css/common.css');
#app {
  font-family: 'Raleway' !important;
}
body{
  background-color: #f3f8fa !important;
  position: relative;
}
body::before{
  content: '';
  position: absolute;
  background: url(./assets/body-bg.png) no-repeat;
  width: 100%;
  height: 100%;
  background-size: inherit;
  min-height: 100vh;
  background-position: right top;
  z-index: -1;
  background-attachment: fixed;
}
a, a:hover{
  color: inherit;
  text-decoration: none !important;
}
</style>

<style lang="scss" >
    //#app {
    //    font-family: Avenir, Helvetica, Arial, sans-serif;
    //    -webkit-font-smoothing: antialiased;
    //    -moz-osx-font-smoothing: grayscale;
    //    text-align: center;
    //    color: #2c3e50;
    //}
    // @import "css/common.css";
    // @import "css/index.css";
    /*@import "css/main.css";*/
    //@import "css/plan_desc.css";
    //@import "scss/main";

    // @import "assets/scss/main.scss";
    .vue-notifyjs {
      .alert {
        z-index: 1080 !important;
        &.alert-with-icon {
          padding-left: 25px !important;
        }
        .alert-icon {
          margin-right: 15px !important;
        }
        .close ~ span {
          display: inline-block !important;
        }
      }
    }
</style>
